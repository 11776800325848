.link-with-icon {
  font-weight: 600;
  cursor: pointer !important;
  transition: color 0.3s ease;
  position: relative;
}

.link-with-icon:hover {
  color: #125d67 !important;
}

.link-with-icon::after {
  content: '🔗';
  position: absolute;
  opacity: 0;
  transform: translateX(5px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  right: -15px;
  transform: translateY(-5%);
}

.link-with-icon:hover::after {
  opacity: 1;
  transform: translateX(10px);
}

td.inventory {
  min-width: 14ch;
}

.separator-line {
  height: 1px;
  background-color: #ccc;
  width: 100%;
  margin: 10px 0;
}

.text-center {
  text-align: center;
}

.fw-bold {
  font-weight: bold;
}

.text-secondary {
  color: #6c757d;
}
