.App.support {
  min-width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-position: top center;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-image: url('../../images/line-mechanic.webp');
}

.App.support .card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2em;
  border-radius: 16px;
  gap: 0.5em;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: white;
  width: 100%;
  max-width: 700px;
  margin-top: 2em;
}

.App.support .card h2 {
  font-size: 2em;
  color: #333;
  text-align: center;
}

.App.support .card p {
  font-size: 1.2em;
  color: #555;
  text-align: center;
  margin-bottom: 1em;
}

.App.support .card .support-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 1em;
  font-size: larger;
}

.App.support .card .support-form .form-group {
  width: 100%;
}

.App.support .card .support-form .form-group label {
  display: block;
  font-size: 1em;
  margin-bottom: 0.2em;
  color: #333;
}

.App.support .card .support-form .form-group input,
.App.support .card .support-form .form-group textarea {
  padding: 0.7em;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 100%;
  font-size: 1em;
}

.App.support .card .support-form .form-group textarea {
  resize: none;
}

.App.support .card .support-form .submit-button {
  width: 100%;
  padding: 0.7em;
  background-color: #f05429;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: relative;
}

.App.support .card .support-form .submit-button::after {
  content: '';
  position: absolute;
  right: 1em;
  top: 50%;
  transform: translateY(-50%) scale(0);
  width: 20px;
  height: 20px;
  background-image: url('../../images/send-icon.svg');
  background-size: contain;
  background-repeat: no-repeat;
  transition: transform 0.3s ease;
}

.App.support .card .support-form .submit-button:hover {
  background-color: #fc4513;
}

.App.support .card .support-form .submit-button:hover::after {
  transform: translateY(-50%) scale(1);
}

.App.support .card .support-form .submit-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  color: #888;
}

.App.support .card .support-form .submit-button:disabled:hover {
  background-color: #ccc; 
}

.App.support .card .support-form .submit-button:disabled::after {
  transform: translateY(-50%) scale(0); /* Hide the icon animation */
}

.error-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.button-container {
  display: flex;
  gap: 1em;
}

.success-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.error-card {
  text-align: center;
}

.retry-button {
  padding: 0.7em;
  background-color: #f05429;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.retry-button:hover {
  background-color: #fc4513;
}

.go-home-button {
  padding: 0.7em;
  background-color: #125d67;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.go-home-button:hover {
  background-color: #10555f;
  color: white;
}

@media (max-width: 768px) {
  .App.support {
    padding: 0.5em;
  }

  .App.support .card {
    width: 90%;
    padding: 1.5em;
  }

  .App.support .card h2 {
    font-size: 1.8em;
  }

  .App.support .card p {
    font-size: 1em;
    margin-bottom: 0.5em;
  }

  .App.support .card .support-form .form-group label {
    font-size: 0.9em;
  }

  .App.support .card .support-form .form-group input,
  .App.support .card .support-form .form-group textarea {
    font-size: 0.9em;
  }

  .App.support .card .support-form .submit-button {
    font-size: 0.9em;
    padding: 0.6em;
  }
}

@media (max-width: 480px) {
  .App.support .card {
    padding: 1em;
  }

  .App.support .card h2 {
    font-size: 1.5em;
  }

  .App.support .card p {
    font-size: 0.9em;
  }

  .App.support .card .support-form .submit-button {
    font-size: 0.8em;
    padding: 0.5em;
  }

  .button-container {
    flex-direction: column;
  }
}

