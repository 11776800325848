.link-with-icon {
  font-weight: 600;
  cursor: pointer !important;
  transition: color 0.3s ease;
  position: relative;
}

.link-with-icon:hover {
  color: #125d67 !important;
}

.link-with-icon::after {
  content: '🔗';
  position: absolute;
  opacity: 0;
  transform: translateX(5px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  right: -15px;
  transform: translateY(-5%);
}

.link-with-icon:hover::after {
  opacity: 1;
  transform: translateX(10px);
}

td.inventory {
  min-width: 14ch;
}

.separator-line {
  height: 1px;
  background-color: #ccc;
  width: 100%;
  margin: 10px 0;
}

.text-center {
  text-align: center;
}

.fw-bold {
  font-weight: bold;
}

.text-secondary {
  color: #6c757d;
}

.table thead th {
  vertical-align: middle;
  font-size: 0.7em;
  background-color: #313131;
  color: #fff;
  padding: 10px;
  border-top: 0;
  border-bottom: 10px solid #bb5d40;
}
.table tr:nth-child(2n),
.table tr:nth-child(2n) {
  background-color: #efefef;
}
.table tbody td {
  font-size: 0.7em;
  padding: 8px 10px;
  vertical-align: middle;
  border: 0;
}
.table tbody td a {
  color: #333;
}
.inventory tr td:last-child {
  text-align: center;
}

.table tbody td a:hover {
  text-decoration: underline;
}

span.sort-icon {
  max-width: 3ch;
  transform: translateY(-2px);
}

span.sort-icon.desc {
  transform: rotate(180deg) translateY(-5px);
}

th:hover {
  cursor: pointer;
}
th {
  white-space: nowrap;
}

.product-table .sticky-top {
  z-index: 40 !important;
}

tr td:first-child {
  white-space: nowrap;
}