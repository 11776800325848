/*
* @Author: babysoft08
* @Date:   2025-01-30 18:31:54
* @Last Modified by:   babysoft08
* @Last Modified time: 2025-02-06 11:45:29
*/
@font-face {
    font-family: 'Telegraf UltraLight';
    font-style: normal;
    font-weight: normal;
    src: local('../fonts/Telegraf UltraLight 200.woff') format('woff');
}
@font-face {
    font-family: 'MinionPro-Regular';
    font-style: normal;
    font-weight: normal;
    src: local('../fonts/MinionPro-Regular.woff') format('woff');
}
@font-face {
    font-family: 'OpenSauceSans-Bold';
    font-style: normal;
    font-weight:Bold;
    src: local('../fonts/OpenSauceSans-Bold.ttf') format('ttf');
}
@font-face {
    font-family: 'OpenSauceSans-Regular';
    font-style: normal;
    font-weight:400;
    src: local('../fonts/OpenSauceSans-Regular.ttf') format('ttf');
}
@font-face {
    font-family: 'OpenSauceSans Light';
    font-style: normal;
    font-weight:Light;
    src: local('../fonts/OpenSauceSans-Light.ttf') format('ttf');
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.headerSection .sub-mega-menu{
	margin:0;
	padding:0 15px;
}
.headerSection .sub-mega-menu li{
	list-style-type:circle;
	margin-bottom: 4px;
	font-family: "Red Hat Display", serif!important;
}
.headerSection .sub-mega-menu li a {
	padding: 0;
}

.headerSection .dropdown-menu.show{
	border-radius: 0;
	border-width:5px 0 0;
	border-style:solid;
	border-color:#125d67;
	margin: 0;
	-webkit-box-shadow: 0 8px 6px -6px black;
	-moz-box-shadow: 0 8px 6px -6px black;
	box-shadow: 0px 8px 20px -15px black;
}
.headerSection .dropdown-menu.show h4 {
	font-family: 'OpenSauceSans-Bold';
	border-bottom: 2px dotted #125d67;
	display: inline-block;
	color: #125d67;
	font-size: 18px;
	margin: 0 0 5px;
	font-weight: 600;
}
.headerSection  .dropdown-item{
	font-size: 14px;
	font-family: "Red Hat Display", serif; 
	font-weight: 400;
	padding: 5px;
	white-space: inherit;
}
.headerSection .dropdown-item:focus, .headerSection .dropdown-item:hover {
	color: #125d67;
	background-color: transparent;
}
.dropdown-menu.show p {
	font-size: 15px;
}

.my-account img {
	width: 15px;
	margin-right: 4px;
	position: relative;
	top: -2px;
}
.right-div li.my-account .dropdown-menu a {
	background-color:transparent;
	font-size: 15px;
	color: #000!important;
	border-radius:0px;
	padding: 4px 8px;
	text-align: left;
	font-weight: 500; 
}
.right-div li.my-account .dropdown-menu a:first-child {
	color: #a8745b !important;
	border-bottom: 2px dotted #a8745b;
}
.right-div li.my-account .dropdown-menu a:hover{
	color: #125d67!important;
}
.headerSection .my-account .dropdown-menu.show {
	border-radius: 5px;
	border: 0;
	margin: 2px 0 0;
}
.navbar-brand img{
	width:200px;
}
.bg-light {
	background-color: #fff !important;
}
.nav-link{
	padding:0;
	display: inline;
}
.navbar-expand-lg .navbar-nav .nav-link {
    padding:10px 25px;
    font-size: 16px;
	color:#000;
	font-family: "Red Hat Display", serif;
} 
.nav-link:focus, .nav-link:hover {
	color:#125d67;
}
.navbar-light .navbar-toggler {
	color: transparent;
	border-color: transparent;
	font-size: 15px;
    border: 0px solid transparent;
    padding: 0 0 0 5px;
}
button:focus {
	outline: 0px dotted;
}
.navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show > .nav-link {
	color: #125d67;
}
.right-div{
	border-left: 2px solid #125d67;
	padding-left: 20px;
}
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
	color: #125d67;
}
.right-div li:last-child a{
	background-color: #125d67;
	color: #fff!important;
	border-radius: 5px;
}
.right-div li a{
	font-weight:700;
}

.navbar .megamenu{ padding: 1rem; }
/* ============ desktop view ============ */
.col-megamenu .title {
	font-size: 15px;
	font-weight: 600;
	font-family: "Red Hat Display", serif;
}
.right-div-login li.my-account .dropdown-menu li:first-child a{
	color: #a8745b !important;
	border-bottom: 2px dotted #a8745b;
}
.right-div-login li.my-account .dropdown-menu a:first-child{
	border:0;
	color: #000 !important;
}
.col-megamenu a{
	color:#000;
	font-size: 15px;
	font-family: "Red Hat Display", serif;
	font-weight: 400;
	padding: 0 0 6px;
}
.col-megamenu a:hover{
	color:#125d67;
}
.right-div-login {
	border-left: 2px solid #125d67;
	padding-left: 20px;
}
.right-div-login li.my-account .dropdown-menu a {
	background-color: transparent;
	font-size: 15px;
	color: #000 !important;
	border-radius: 0px;
	padding: 4px 8px;
	text-align: left;
	font-weight: 500;
}
.right-div-login li.end-img img{
	width: 45px;
	position: absolute;
	margin-top: -11px;
	border-radius: 50px;
	height: 45px;
}
.right-div-login li:first-child a {
	background-color: #125d67;
	border-radius: 5px;
}
.right-div-login a.nav-link.dropdown-toggle{
	color: #fff;
}
.right-div-login a.nav-link.dropdown-toggle:hover, .right-div-login a.nav-link.dropdown-toggle:focus{
	color: #fff;
}
.dropdown-menu {
    z-index: 9999;
    max-height: inherit;
    overflow: inherit;
}

/*** Login Area Css Start ***/
.login-modal-form .modal-body {
  padding: 3rem 5rem;
}
.login-modal-form .modal-content {
  background-color: #323232;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
  color: #fff;
}
.login-modal-form .modal-header {
  border: 0;
}
.login-modal-form .btn-close {
  opacity: 1;
  background-color: #fff;
}
.login-modal-form .login-form p a {
  color: #fff;
  font-size:1.2em;
  font-family: "Montserrat", serif;
}
.sign-up-text {
  text-align: center;
  text-decoration: underline;
  font-size: 20px;
  font-weight: 500;
}
.sign-up-text a:hover {
  color: #f64d1f;
}
.header-right-div ul {
  padding: 0;
}
.btn.login-btn {
  background-color: #208dc0;
  color: #fff;
  padding: 8px 18px;
  border-radius: 0;
  display: table;
  margin: 0 0 0 auto;
  cursor: pointer;
}
.login-modal-form .form-control {
  background-color: #faffbd;
}
.login-modal-form .form-control:focus {
  background-color: #fff;
}

.login-area .black-bg {
  background-color: #313131;
  padding: 20px 20px 0;
}
.login-area h3 {
  font-size: 2.25em;
  font-weight: bold;
  color: #fff;
}
.login-area h4 {
  font-size: 1.2em;
}
.login-area .gray-bg {
  background-color: #f1f1f1;
  padding: 20px 30px;
}

#sign_up_user .form-control::placeholder {
  color: #919191;
  opacity: 1;
}
.login-form i {
  font-size: 25em;
  margin-top: 140px;
  position: relative;
  left: 115px;
  color: rgba(60, 60, 60, 0.9);
  bottom: 1px;
}
#sign_up_user .form-control {
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 0;
  background-color: #fdfdfd;
}
.login-form small {
  font-size: 0.5em;
  color: #fff;
  display: block;
}
.login-form .btn-link {
  font-size: 0.8em;
  color: #fff;
}
#sign_up_user .btn-primary {
  font-size: 1em;
  margin: 0;
  padding: 6px 15px;
}
#sign_up_user .form-control:focus {
  color: #333;
  background-color: #fff;
  border-color: #ddd;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0);
}
.login-form .btn-link:hover {
  color: #fff;
}
.login-form label {
  font-size: 0.8em;
  font-weight: bold;
  color: #fff;
}
label.custom-control-label {
	margin-left: 6px;
    position: relative;
    top: -3px;
	font-size: 0.8em;
	font-weight: bold;
	color: #fff;
	font-family: "Montserrat", serif;
}
.modal-title {
  font-family: "Montserrat", serif;
  font-weight:500;
}
/*** Login Area Css End ***/
/* ============ desktop view .end// ============ */

@media all and (min-width: 992px) {		
	.navbar .has-megamenu{
		position:static!important;
	}
	.navbar .megamenu{
		left:0; 
		right:0;
		margin: 0;
	}		
}
/* ============ mobile view ============ */
@media (min-width: 1200px) {
	.container {
		max-width: 1200px;
	}
}
@media (max-width:767px){
	.headerSection .my-account .dropdown-menu.show{
		padding: 0 5px;
	}
	.navbar .megamenu{ padding:10px 0; }
	.login-modal-form .modal-body {
		padding: 20px;
	}
	.navbar-brand img {
		width: 100px;
	}
	.right-div {
		border-left: 0px solid #125d67;
		padding-left: 0;
	} 
	.right-div {
		border-left: 0px solid #125d67;
		padding-left: 0;
		margin: 0 0 0 auto;
	}
  .headerSection .dropdown-item{
		/* padding:5px 0; */
		font-size: 13px;
	}
	.right-div a:hover{
		color: #125d67;
	}
   .right-div a:last-child {
		border-right: medium none;
	}
	.right-div a {
	  	font-family: "Red Hat Display", serif;
	  	font-size: 13px;
	    color: #000;
	    padding: 0 5px;
	    font-weight: 700;
	    border-right: 2px solid #125d67;
	}
	.navbar-expand-lg .navbar-nav .nav-link {
		padding:6px 0;
	}
	.nav-link{
		display: inline-block;
	}
	.right-div-login{ 
		display:none;
	}
	.right-div-login .nav-link{
		padding: 0 5px;
	}
	.right-div-login .dropdown.my-account .nav-link.dropdown-toggle{
		color: #000;
		font-weight: 700;
		font-size: 12px;
		font-family: "Red Hat Display", serif;
	}
	.right-div-login li:first-child a {
		background-color: transparent !important;
		color: rgb(168, 116, 91);
		border-bottom: 2px dotted rgb(168, 116, 91);
		border-radius: 0;
	}
	.right-div-login {
		border-right: 2px solid #125d67;
		padding-left: 0!important;
		border-left:0!important;
		margin: 0 0 0 auto;
	}
	.my-account img {
		width: 12px;
	}
	ul.navbar-nav.ms-auto.right-div {
		display: none;
	}
	.navbar-brand {
		padding:0;
		margin-right:0;
		font-size: inherit;
	}
}
@media(max-width: 991px){
	.navbar.fixed-top .navbar-collapse, .navbar.sticky-top .navbar-collapse{
		overflow-y: auto;
		max-height: 90vh;
		margin-top:10px;
	}
}
@media (min-width:768px) and (max-width:991px){
	.navbar-brand {
		padding:0;
		margin-right:0;
		font-size: inherit;
	}
	.navbar-expand-lg .navbar-nav .nav-link {
		padding:6px 0;
	}
	.right-div {
		border-left: 0px solid #125d67;
		padding-left: 0;
		margin: 0 0 0 auto;
	}
	.right-div a:hover{
		color: #125d67;
	}
   .right-div a:last-child { 
		border-right: medium none;
	}
	.right-div a {
		color: #000;
		padding: 0 5px;
		font-weight: 700;
		font-size: 13px;
		border-right: 2px solid #125d67;
		font-family: "Red Hat Display", serif;
	}
	.headerSection.trs .container{
		max-width: inherit;
	}
	.nav-link{
		display: inline-block;
	}
	.navbar-nav.ms-auto.right-div {
		display: none;
	}
	.headerSection .my-account .dropdown-menu.show{
		padding: 0 5px;
	}
	.right-div-login{
		display:none;
	}
	.right-div-login .nav-link{
		padding: 0 5px;
	}
	.right-div-login .dropdown.my-account .nav-link.dropdown-toggle{
		color: #000;
		font-weight: 700;
		font-family: "Red Hat Display", serif;
		font-size:14px;
	}
	.right-div-login li:first-child a {
		background-color: transparent !important;
		color: rgb(168, 116, 91);
		border-bottom: 2px dotted rgb(168, 116, 91);
		border-radius: 0;
	}
	.right-div-login {
		border-right: 2px solid #125d67;
		padding-left: 0!important;
		border-left:0!important;
		margin: 0 0 0 auto;
	}
}
@media (min-width: 992px) and (max-width: 1199px) {
	.navbar-expand-lg .navbar-nav .nav-link {
		padding-right: 15px;
		padding-left: 15px;
		font-size: 15px;
	}
}