.custom-select-container {
  width: 100%;
  min-width: 200px;
}

.custom-select-menu {
  width: 100%;
}

.search-by-part-number label {
  white-space: nowrap;
}

.custom-select-container * {
  white-space: nowrap;
}