.clear-btn-sidebard {
  transition: font-weight 0.3s ease;
}

.clear-btn-sidebard:hover {
  background-color: #ebebeb;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.clear-btn-sidebard:disabled {
  cursor: not-allowed;
}

.sidebar h5 {
  white-space: nowrap;
}