.motor-table {
  border-top: 10px solid rgb(187, 93, 64);
  border-bottom: 10px solid rgb(187, 93, 64);
}

.motor-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}

.motor-modal-content {
  background: #fff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 650px;
  z-index: 1060;
}

.submit-error-report {
  background-color: #f05429;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: relative;
}

.motor-error-report-form label {
  margin-top: 10px;
}

.form-group.read-only {
  user-select: none !important;
  cursor: not-allowed;
  pointer-events: none;
}
