.chevron-img {
  width: 23px;
  cursor: pointer;
  user-select: none;
}

.sidebar-accordion-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  text-wrap: nowrap;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #000;
  min-width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
}

.divider-side-bar {
  height: 2px;
  background-color: #125d67;
}

.sidebar-accordion-button:hover {
  background-color: rgba(0, 127, 119, 0.1);
  border-radius: 3px;
}

.checkbox-input-sidebar *:hover {
  cursor: pointer !important;
}

.checkbox-input-sidebar input[type='checkbox'] {
  width: 15px;
  height: 15px;
}

.checkbox-input-sidebar input[type='checkbox'] {
  accent-color: #125d67;
}

.list-checkboxes {
  max-height: 150px;
  overflow-y: auto;
}
.label-sidebar {
  color: #000;
}


.search-container {
  position: relative;
  display: flex;
  align-items: center;
}

.text-input-sidebar {
  border-radius: 40px;
  background-color: #ECECEB;
  padding: 5px 40px 5px 15px;
  border: none;
  outline: none;
  width: 100%;
}

.search-icon {
  position: absolute;
  right: 15px;
  width: 20px;
  height: 20px;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

label.label-sidebar {
  margin-left: 0.3em;
}

.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.accordion-content.open {
  max-height: 205px;
}